<template>
    <div>
        <div class="weather">我参与的项目</div>
        <van-search placeholder="搜索项目" readonly shape="round" @click="openSearchBox"/>
        <div class="glo-content">
            <div v-if="loading">
                <van-loading type="spinner" vertical>加载中</van-loading>
            </div>
            <div v-else>
                <div v-if="groupList.length == 0">
                    <van-empty description="您参与的项目为空" />
                </div>
                <div>
                    <van-row class="flex-inner">
                        <van-col class="block-show" span="12" v-for="(item,index) in groupList" :key="index" @click="enterProject(item)">
                            <div>
                                <div class="item-img"><img :src="item.pic.path || require('@/assets/slope/img/item-img.jpg')"></div>
                                <div class="item-text">{{item.devName}}</div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="blockSearch" class="block-search">
                <div  class="search-box">
                    <van-search v-model="keyword" placeholder="请输入项目名称" shape="round" @input="watchSearch"/>
                    <div class="search-list">
                        <van-cell icon="label-o" v-for="(item,index) in filterData" :key="index" :title="item.devName" :value="item.devCode" @click="enterProject(item)"/>
                    </div>
                </div>
                <van-overlay :show="overlayShow" @click="overlayCancel" />
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data(){
        return{
            groupList:[],
            pageNum:1,
            pageSize:200,
            loading:true,
            blockSearch:false,
            overlayShow:true,
            keyword:'',
            filterData:[]
        }
    },
    methods:{
        // 首次进来加载桥梁列表
        onLoad(){
            const projectCode = window.sessionStorage.getItem('projectCode');
            // 0是桥，1是边坡
            this.$api.SLOPE.getProjectPage({
                devType:1,projectCode:projectCode
            }).then( d => { 
                this.groupList = d;
                this.loading = false;
            })
        },
        // 进入桥梁检测系统
        enterProject(i){
            if(window.localStorage.getItem('visitor') == '1'){
                this.$toast('您的身份是游客，暂无权限进入设备面板')
                return false;
            }
            window.sessionStorage.setItem('title',i.devName);
            window.sessionStorage.setItem('bridgeCode',i.devCode);
            // 前端分配进入桥梁主题页面
            switch(i.devCode){
                case '510321001':
                    this.$router.push({path:'/slope/rongxian/home'});
                    break;
                case '510321003':
                    this.$router.push({path:'/slope/baiyanshi/home'});
                    break;
            }
        },
        // 监听input值动态筛选
        watchSearch(k){
            let Arr = this.groupList;
            this.filterData =  Arr.filter( e => {return e.devName.includes(k)});
        },
        // 隐藏搜索框
        overlayCancel(){
            this.keyword='';
            this.blockSearch = false;
            this.filterData = this.groupList;
        },
        // 打开搜索框
        openSearchBox(){
            this.blockSearch = true;
            this.filterData = this.groupList;
        }
    },
    mounted(){
        this.onLoad();
        window.sessionStorage.removeItem('onceAlarm');// 清空上一项目的告警数
        window.sessionStorage.setItem('magbot',1);// 赋值控制告警样式
    }
}
</script>
<style lang="scss" scoped>
.weather{
    margin-bottom: 10px;
    height: 4rem;
    background: linear-gradient(#2a90e2 0%, #66b8fb 85%);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: #fff;
    line-height: 4;
    font-size: .95rem;
    font-weight: bold;
}
.item-img{
    width: 100%;height: 100px;
    img{
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
.item-text{
    padding: 5% 2%;
    font-size: .8rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    margin-bottom: 15%;
    background: linear-gradient(#2a90e2 15%, #66b8fb 90%);
}
.block-show{
    &:nth-child(odd){
        padding-right: 15px;
    }
    &::nth-child(even){
        padding-left: 15px;
    }
    &:hover{
        transform: translate(0,-5px);
        opacity: 0.7;
    }
}
.flex-inner{
    display: flex;
    flex-wrap: wrap;
}
.block-search{
    .search-box{
        z-index: 999;
        position: fixed;
        width: 100%;
        top: 0;
        .van-search{padding:15px;}
        .search-list{
            text-align: left;
            max-height: 22rem;
            overflow-y: scroll;
            .van-cell{padding: 16px 16px;}
        }
    }
    .van-overlay{z-index: 99;opacity: 0.8;}
}
.fade-leave-active,.fade-enter-active {
    transition: all .5s ease;
}
.fade-enter,.fade-leave-to{
    opacity: 0;
}
</style>